<template>
  <div class="col-lg-3 center">
    <div class="card ">
      <div class="card-body text-center" v-if="student">
        <img
          v-if="student.user"
          :src="`${baseUrl}e95c2c2dd3ef780c269f75ee3286df1c418bc016/${'profile_images'}/${student.user.image}`"
          alt="user-image"
          class="rounded-circle"
          style="max-width:150px;max-height:150px;"
        />
        <h3 class="mt-3 mb-0" v-if="student.user">{{student.user.name}}</h3>
        <div class="text-left mt-3">
          <div class="table-responsive">
            <table class="table table-borderless table-sm">
              <tbody>
              <tr>
                <th scope="row">Full Name:</th>
                <td class="text-muted" v-if="student.user">{{student.user.name}}</td>
              </tr>
              <tr>
                <th scope="row">Phone:</th>
                <td class="text-muted" v-if="student.user">
                  {{student.user.phone_number}}
                </td>
              </tr>
              <tr>
                <th scope="row">Stage:</th>
                <td class="text-muted" v-if="student['educational _year']">
                  {{student["educational _year"]}}
                </td>
              </tr>
              <tr>
                <th scope="row">School :</th>
                <td class="text-muted" >
                  {{student.school}}
                </td>
              </tr>
              <tr>
                <th scope="row">Balance :</th>
                <td class="text-muted" >
                  {{student.balance}}
                </td>
              </tr>
              <tr>
                <th scope="row">Attended Physical:</th>
                <td class="text-muted" >
                  {{ number }}
                </td>
              </tr>
              <tr>
                <th scope="row">Attended Online:</th>
                <td class="text-muted" >
                  {{ numberOnline }}
                </td>
              </tr>
              <tr>
                <th scope="row">Total Attended:</th>
                <td class="text-muted" >
                  {{ number + numberOnline }}
                </td>
              </tr>
              </tbody>
            </table>
            <b-button pill variant="warning" style="width: 12rem;" class="text-center justify-center" :to="`/students/${$route.params.student}/fantasy`">Fantasy</b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'StudentSidebar',
    props: ['student'],
    async created(){
      this.baseUrl = process.env.VUE_APP_BASE_URL;
      await this.$store.dispatch('statistics/getStudentStat',this.$route.params.student).then(()=>{
        this.number = this.$store.getters['statistics/studentCount'];
      });
      await this.$store.dispatch('statistics/getStudentStatOnline',this.$route.params.student).then(()=>{
        this.numberOnline = this.$store.getters['statistics/studentCountOnline'];
      }).then(()=>{}).catch( err =>{
        // errorHandler.methods.errorMessage(err);
        console.log("No items")
      });
    },
    data(){
      return {
        number:0,
        numberOnline:0,
        baseUrl:null,
      }
    },
  }
</script>

<style scoped>

</style>
